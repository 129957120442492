export default class Menu {

    constructor( element ) {

        this.element = element;

        this.body;
        this.documentElement;
        this.hamburger;
        this.menuClose;
        this.menuOpen;
        this.menuContainer;

        this.toTop;

        this.scrollTop = 0;
        this.scrollTopNew = 0;
        this.scrollThreshold = 150;
        this.scrollDifference = 0;

        this.initMenu();

    }

    initMenu() {

        console.log('init menu');
        // this.documentElement = document.documentElement.body;
        this.body = document.body;
        this.hamburger = this.element.querySelector( '[data-hamburger]' );
        this.menuContainer = document.querySelector( '.menu' );
        // this.menuClose = this.element.querySelector( '[data-navigation-close]' );
        // this.menuOpen = this.element.querySelector( '[data-navigation-open]' );

        this.hamburger.addEventListener( 'click', ( event ) => { this.toggleMenu( event ); } );

        if ( this.element.hasAttribute( 'data-scroll' ) ) {
            this.body.onscroll = ( event ) => { this.scroll( event ); };
            this.scroll();
        }

        this.menuContainer.addEventListener('click', ( event ) => { this.handleMenuClick( event ); } )
    }

    handleMenuClick( event ) {
        if (this.menuContainer.hasAttribute( 'data-visible' ) && event.target instanceof HTMLAnchorElement) {
            this.toggleMenu();
          }
    }

    toggleMenu( event ) {

        if ( this.element.hasAttribute( 'data-open' ) ) {
            this.element.removeAttribute( 'data-open');
            this.menuContainer.removeAttribute( 'data-visible' );
            // this.menuClose.removeAttribute( 'data-visible' );
            // this.menuOpen.setAttribute( 'data-visible', 'true' );
            this.body.classList.remove( 'no-scroll' );

        } else {

            this.element.setAttribute( 'data-open', 'true' );
            this.menuContainer.setAttribute( 'data-visible', 'true' );
            // this.menuOpen.removeAttribute( 'data-visible' );
            // this.menuClose.setAttribute( 'data-visible', 'true' );
            this.body.classList.add( 'no-scroll' );
            console.log(this.body);
        }
    }

    scroll( event ) {

        this.scrollTopNew = window.pageYOffset || document.documentElement.scrollTop;


        if ( this.element.getAttribute( 'data-visible' ) === 'true' ) {
            this.scrollDifference = this.scrollTopNew - this.scrollTop;

            if ( this.scrollDifference < 0 ) {
                this.scrollTop = this.scrollTopNew;
            } else if ( this.scrollDifference > this.scrollThreshold ) {
                this.element.setAttribute( 'data-visible', 'false' );

                this.scrollTop = this.scrollTopNew;
            }

        } else {

            if ( this.scrollTopNew < this.scrollTop ) {
                this.element.setAttribute( 'data-visible', 'true' );
                this.element.setAttribute( 'data-scrolled', 'true' );
            } 

            this.scrollTop = this.scrollTopNew;
        }

      

    }


}